import { loadable } from 'nordic/lazy';

export const PI = 'PI';
export const CATEGORY = 'CATEGORY';
export const RESCUE = 'RESCUE';
export const DEFAULT = 'ML';

const RescueDesktop = loadable(async () => import('../views/rescue/rescue.desktop'));
const SearchDesktop = loadable(async () => import('../views/main/main.desktop'));
const SearchDesktopPI = loadable(async () => import('../views/main/main.desktop.pi'));
const SearchDesktopCAT = loadable(async () => import('../views/categories/cat.desktop'));

export const DesktopView = (props) => {
  const { componentMapId } = props;

  switch (componentMapId) {
    case RESCUE:
      return <RescueDesktop {...props} />;
    case PI:
      return <SearchDesktopPI {...props} />;
    case CATEGORY:
      return <SearchDesktopCAT {...props} />;

    default:
      return <SearchDesktop {...props} />;
  }
};
